<template>
  <li>
    <c-radio
      type="radio"
      name="radio-plan"
      id="radio-plan-monthly"
      :value="pricing.id"
      v-model="selected"
    >
      <label
        class="form-check-label"
        for="radio-plan-standard"
        @click="() => $emit('input', pricing.id)"
      >
        {{ pricing.name }}
        <span v-if="isEnterprisePlan" class="price">Price on Application</span>
        <span v-else class="price">{{ price }} /month</span>
        <span class="description" style="white-space: pre-line;">{{ pricing.description }}</span>
      </label>
    </c-radio>
  </li>
</template>

<script>
export default {
  props: {
    pricing: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
      required: false // The value is required, but we need to allow nullable
    },
  },

  computed: {
    price () {
      return this.formatPrice(this.pricing.price)
    },

    selected: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },

    isEnterprisePlan () {
      return this.pricing.name === 'Enterprise'
    }
  },

  methods: {
    formatPrice (price) {
      return (price / 100).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    }
  },
}
</script>
